import React, { MouseEvent, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import '../components/css/pieces.css';
import { Dialog } from '../components/dialog';

interface File {
    id: string;
    absolutePath: string;
    modifiedTime: Date;
    extension: string;
    size: number;
    name: string;
    relativePath: string;
    publicURL: string;
    childImageSharp: any;
}

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark;
    const images = (frontmatter.images as File[]).filter(Boolean);

    const [currentImage, setCurrentImage] = useState(images[0].publicURL);
    const handleDialog = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setCurrentImage(e.currentTarget.href);
    }, []);

    return (
        <Layout>
            <SEO title={frontmatter.title} />
            <header className="small">
                <h1>{frontmatter.title}</h1>
                {frontmatter.subtitle && <p>{frontmatter.subtitle}</p>}
            </header>
            <div className="image filtered" data-position="center">
                <img src={currentImage} alt={frontmatter.subtitle} />
            </div>

            <div className="inner">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    {images.map(i => (
                        <a
                            href={i.publicURL}
                            style={{ border: 'none', textAlign: 'center' }}
                            key={i.id}
                            onClick={handleDialog}
                        >
                            <img
                                src={i.childImageSharp.fixed.src}
                                style={{ width: '80%' }}
                                alt={i.name}
                            />
                        </a>
                    ))}
                </div>
                <hr />

                {frontmatter.etsy && (
                    <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                        <a
                            href={frontmatter.etsy}
                            target="_blank"
                            rel="noopener nofollow external"
                            className="purchase"
                        >
                            <i className="fa fa-shopping-cart"></i>
                            Available for Purchase on Etsy!
                        </a>
                    </div>
                )}

                <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                etsy
                title
                subtitle
                images {
                    id
                    name
                    relativePath
                    modifiedTime
                    publicURL
                    childImageSharp {
                        fixed(width: 300) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
`;
